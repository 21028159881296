import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlogHero from '../../components/sections/blog-hero';
import { BlogPost } from '../../models/blog-post';
import { Route } from '../../models/site-links';
import { gaButtonEvent } from '../../services/visitor-info';
import Img from 'gatsby-image';
import './articlestyle.css';

const ServingAlcoholBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      servingAlcohol: file(relativePath: { eq: "blog/serving-alcohol.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ny1: file(relativePath: { eq: "blog/newyork_scrubbed.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ny2: file(relativePath: { eq: "blog/ny_scrubbed.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const blogHeroPost: BlogPost[] = [
    {
      heading: 'Latest Trusted Partner: Serving Alcohol',
      description:
        'Patronscan is pleased to announce our partnership with Serving Alcohol in preventing the sale of alcohol to minors. Serving Alcohol provides certifications to sell alcohol responsibly across the United States',
      miniDescription: 'This should really only be about 5 words',
      image: data.servingAlcohol.childImageSharp.fluid,
      link: '/blog/serving-alcohol-partnership',
    },
  ];

  return (
    <div className="flex-col justify-center">
      <div className="flex justify-center mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mt-toplg">
        <BlogHero blogMeta={blogHeroPost}></BlogHero>
      </div>
      <div className="flex justify-center">
        <div className="mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg article-container">
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            Latest Trusted Partner: Serving Alcohol
          </h2>
          <p className="article-body text-sb lap:text-mb">
            <a
              href="https://servingalcohol.com/serving-alcohol-and-patronscan-partnership/"
              className="text-blue-old"
            >
              Serving Alcohol
            </a>{' '}
            provides online certifications for responsible alcohol sales for all 50 states in the
            USA. Having experienced the effectiveness of Patronscan’s ID scanners in preventing the
            sale to minors, they recommend using it as part of their responsible serving strategy.{' '}
            <br></br>
            <br></br>
            Underaged patrons that purchase alcohol using a fake ID and subsequently become
            inebriated pose significant risks to business owners and staff. With fake IDs easier to
            buy than ever, costing as little as $30, it’s important to have the right technology to
            spot them.<br></br>
            <br></br>
            Many of them are almost indiscernible from real IDs and it’s more important than ever to
            use advanced ID scanners that check for pixel perfection.
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            Spotting Fake IDs with the Naked Eye
          </h2>
          <p className="article-body text-sb lap:text-mb">
            When it comes to catching fake IDs, trained employees may be able to spot lower quality
            fake IDs that more familiar. The problem is most minors bring high-quality out-of-state
            IDs that are much harder to spot. <br></br>
            <br></br>
            Take a look at these two New York IDs and try to spot the fake.
          </p>
          <div className="side-by-side-ids flex flex-col tab:flex-row">
            <Img
              fluid={data.ny2.childImageSharp.fluid}
              className="flex my-4 tab:my-8 justify-center flex-row w-3/4 tab:w-5/12"
            ></Img>
            <Img
              fluid={data.ny1.childImageSharp.fluid}
              className="flex my-4 tab:my-8 justify-center flex-row w-3/4 tab:w-5/12"
            ></Img>
          </div>
          <p className="article-body text-sb lap:text-mb">
            The first one is real. The second one is fake and is one of the most popular fake IDs in
            circulation.<br></br>
            <br></br>A little tricky?<br></br>
            <br></br>
            Maybe a bit easier if you’re from New York.<br></br>
            <br></br>
            For our team at Patronscan, it’s been a 15-year long and arduous process to develop our
            authentication technology that’s capable of catching these high-quality fake IDs from
            not just every state in the United States but across the world.
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh my-6">
            Differences in ID Scanning Technology
          </h2>
          <p className="article-body text-sb lap:text-mb">
            An important caveat with ID scanning is not all ID scanners are effective in fake ID
            detection. In fact, most will fail to catch lower quality templates and check for valid
            barcodes only.<br></br>
            <br></br>
            Developing a scanning engine that is effective in identifying the slightest
            discrepancies (while accounting for dirty/damaged IDs) requires an incredible number of
            scans, machine learning algorithms and and a hyper-dedicated team.<br></br>
            <br></br>
            It’s why, 115 million scans later, Patronscan is the most recommended ID scanner
            worldwide.
          </p>
          <br></br>
          <h2>
            Get{' '}
            <a
              href={Route.TalkToAnExpert}
              onClick={() => {
                gaButtonEvent('intouchwithustoday', `ServingAlcoholPost:intouchwithustoday`);
              }}
              className="text-blue-old"
            >
              in touch with us today{' '}
            </a>
            to learn how easy it is to adopt Patronscan for your business.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ServingAlcoholBlog;
